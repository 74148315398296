import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import UnibicNav from "./components/navbar/unibicNavbar";

import Login from "./pages/login";

import OutletMaps1 from "./components/OutletMaps1";

const Navigator = () => {
  const [authError, setAuthError] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!sessionStorage.getItem("access_token")
  );
  const [currentLocation, setCurrentLocation] = useState(null);
  const [currentAddress, setCurrentAddress] = useState("");
  const [filteredOutlets, setFilteredOutlets] = useState([]);
  const [directions, setDirections] = useState(null);
  const [wayPoints, setWayPoints] = useState([]);
  const [IsExpanded, setIsExpanded] = useState(false);
  const handleLogin = async (username, password, navigate) => {
    const loginApi = process.env.REACT_APP_LOGIN_API;
    const response = await fetch(loginApi, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });
    const loginData = await response.json();
    if (!response.ok) {
      setAuthError(loginData.error);
    } else {
      const userDetails = {
        username: loginData?.username,
        name: loginData?.name,
        role: loginData?.role,
      };

      setIsAuthenticated(true);
      sessionStorage.setItem("access_token", loginData?.token);
      sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
      sessionStorage.setItem("Beats", [loginData?.beats]); 
      navigate("/home");
    }
  };

  const handleLogout = async (username) => {
    const logoutApi = process.env.REACT_APP_LOGOUT_API;
    const access_token = sessionStorage.getItem("access_token");
    const response = await fetch(logoutApi, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      body: JSON.stringify({ username }),
    });

    setIsAuthenticated(false);
    setFilteredOutlets([]);
    setCurrentLocation(null);
    setCurrentAddress("");
    setDirections(null);
    setWayPoints([]);
    setIsExpanded(false);
    setDirections(null);
    setWayPoints([]);
    sessionStorage.clear();
  };

  return (
    <Router>
      <UnibicNav
        isAuthenticated={isAuthenticated}
        handleLogout={handleLogout}
      />
      <Routes>
        <Route
          path="/login"
          element={
            <Login
              onLogin={handleLogin}
              authError={authError}
              setAuthError={setAuthError}
            />
          }
        />

        <Route
          path="/home"
          element={
            isAuthenticated ? (
              <OutletMaps1
                currentLocation={currentLocation}
                setCurrentLocation={setCurrentLocation}
                currentAddress={currentAddress}
                setCurrentAddress={setCurrentAddress}
                filteredOutlets={filteredOutlets}
                setFilteredOutlets={setFilteredOutlets}
                directions={directions}
                setDirections={setDirections}
                wayPoints={wayPoints}
                setWayPoints={setWayPoints}
                setIsExpanded={setIsExpanded}
                IsExpanded={IsExpanded}
              />
            ) : (
              <Login onLogin={handleLogin} />
            )
          }
        />
        <Route
          path="/"
          element={<Navigate to={isAuthenticated ? "/home" : "/login"} />}
        />
      </Routes>
    </Router>
  );
};

export default Navigator;
