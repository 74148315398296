import React, { useState, useEffect } from "react";
import InfoContainer1 from "./InfoContainer1";
import MapContainer1 from "./MapContainer1";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import SlidingComponent1 from "./SlidingComponent1";
const OutletMaps1 = ({
  currentLocation,
  setCurrentLocation,
  currentAddress,
  setCurrentAddress,
  filteredOutlets,
  setFilteredOutlets,
  directions,
  setDirections,
  wayPoints,
  setWayPoints,
  setIsExpanded,
  IsExpanded,
}) => {
  const [hasSearched, setHasSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [googleMapsLink, setGoogleMapsLink] = useState(null);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [eta, setEta] = useState(null);
  const [radius, setRadius] = useState(50);
  const [selectedSortOption, setSelectedSortOption] = useState("probability");
  const [selectedBeat, setSelectedBeat] = useState("");
  const [includeCommute, setIncludeCommute] = useState(false);
  const [showAllWaypoints, setShowAllWaypoints] = useState(false);
  const [isOutletRemoved, setIsOutletRemoved] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    categorySize: false,
    nonBilled: false,
  });

  const [categorySizeFilters, setCategorySizeFilters] = useState({
    ALL: false,
    "500001-ABOVE": true,
    "400001-500000": false,
    "300001-400000": false,
    "200001-300000": false,
    "100001-200000": false,
    "75001-100000": false,
    "50001-75000": false,
    "30001-50000": false,
    "20001-30000": false,
    "15001-20000": false,
    "10001-15000": false,
    "8001-10000": false,
    "5001-8000": false,
    "2001-5000": false,
    "0-2000": false,
  });

  const [nonBilledFilters, setNonBilledFilters] = useState({
    ALL: { filterValue: "ALL", isSelected: false },
    "7 days": { filterValue: 7, isSelected: true },
    "14 days": { filterValue: 14, isSelected: false },
    "30 days": { filterValue: 30, isSelected: false },
    "60 days": { filterValue: 60, isSelected: false },
  });

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState("");
  const [errorRadius, setErrorRadius] = useState("");
  const [useCurrentLocation, setUseCurrentLocation] = useState(true);
  const [selectedTime, setSelectedTime] = useState({ hours: 8, minutes: 0 });

  useEffect(() => {
    if (useCurrentLocation) {
      fetchCurrentLocationAndAddress();
    } else {
      handleUpdateLocation();
    }
  }, [useCurrentLocation]);

  const handleUpdateLocation = async () => {
    const newLocation = {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    };
    setCurrentLocation(newLocation);
    await fetchCurrentAddress(newLocation);
  };

  useEffect(() => {
    fetchCurrentLocationAndAddress();
  }, []);
  const fetchCurrentLocationAndAddress = () => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        const TOLERANCE = 0.0001;
        if (
          currentLocation &&
          Math.abs(currentLocation.lat - location.lat) < TOLERANCE &&
          Math.abs(currentLocation.lng - location.lng) < TOLERANCE
        ) {
          return;
        }
        setCurrentLocation(location);
        await fetchCurrentAddress(location);
      },
      (error) => {
        console.error("Error fetching location:", error);
      }
    );
  };
  const selectAllOutlets = () => {
    setWayPoints((prevSelected) => {
      if (prevSelected.length === filteredOutlets.length) {
        return [];
      } else {
        console.log(
          "All available outlets when selecting all:",
          filteredOutlets
        );
        return filteredOutlets;
      }
    });
  };

  const fetchCurrentAddress = async (location) => {
    const access_token = sessionStorage.getItem("access_token");
    try {
      const response = await fetch(`${process.env.REACT_APP_GEOCODE_API}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify({
          lat: location.lat,
          lng: location.lng,
        }),
      });
      const data = await response.json();
      setCurrentAddress(data.response || "Address not found");
    } catch (err) {
      console.error("Error fetching address from backend:", err);
      setCurrentAddress("Unable to fetch address");
    }
  };

  function validateRadius(radius, setErrorRadius) {
    if (!radius || radius <= 0) {
      setErrorRadius("Radius must be greater than 0");
      return false;
    }
    setErrorRadius("");
    return true;
  }
  async function fetchOutletsFromAPI(
    currentLocation,
    radius,
    beat,
    filters,
    sort,
    totalMinutes,
    includeCommute
  ) {
    const apiUrlOutlets = process.env.REACT_APP_OUTLETS_API;
    const access_token = sessionStorage.getItem("access_token");
    const response = await fetch(apiUrlOutlets, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      body: JSON.stringify({
        currentLocation,
        radius,
        beat,
        filters,
        sort,
        timeConstraint: totalMinutes,
        includeCommute,
      }),
    });
    return response.json();
  }

  async function fetchFilteredOutlets() {
    if (!currentLocation) return;
    if (!validateRadius(radius, setErrorRadius)) return;
    setHasSearched(true);
    setRadius(radius);
    setIsLoading(true);

    try {
      const activeCategoryFilters = Object.keys(categorySizeFilters).filter(
        (key) => key !== "ALL" && categorySizeFilters[key]
      );

      const activeNonBilledFilters = Object.keys(nonBilledFilters)
        .filter((key) => key !== "ALL" && nonBilledFilters[key].isSelected)
        .map((key) => nonBilledFilters[key].filterValue);

      const filters = {
        ...(selectedFilters.categorySize &&
          activeCategoryFilters.length > 0 && {
            categorySize: activeCategoryFilters,
          }),
        ...(selectedFilters.nonBilled &&
          activeNonBilledFilters.length > 0 && {
            nonBilled: activeNonBilledFilters,
          }),
      };
      const totalMinutes = selectedTime.hours * 60 + selectedTime.minutes;

      let response = await fetchOutletsFromAPI(
        currentLocation,
        radius,
        selectedBeat,
        filters,
        selectedSortOption,
        totalMinutes,
        includeCommute
      );
      if (response) {
        const nnaPathSet = new Set(response.route.path);
        const filteredNNAOutlets = (response.outlets || []).filter((outlet) =>
          nnaPathSet.has(outlet.OutletId)
        );
        const orderedFilteredNNAOutlets = response.route.path
          .map((outletId) =>
            filteredNNAOutlets.find((outlet) => outlet.OutletId === outletId)
          )
          .filter(Boolean);

        setFilteredOutlets(orderedFilteredNNAOutlets);
        fetchDirections(orderedFilteredNNAOutlets);
      }
    } catch (error) {
      console.error("Error fetching outlets:", error);
      setFilteredOutlets([]);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchDirections = async (
    selectedOutlets,
    optimizeRoute = false,
    isTrimmed = false
  ) => {
    if (!currentLocation || !selectedOutlets || selectedOutlets.length === 0) {
      return null;
    }
    const totalMinutes = selectedTime.hours * 60 + selectedTime.minutes;
    const directionsService = new window.google.maps.DirectionsService();
    const maxWaypoints = 23;
    const waypoints = selectedOutlets.map((outlet) => ({
      location: {
        lat: parseFloat(outlet?.Latitude),
        lng: parseFloat(outlet?.Longitude),
      },
      stopover: true,
    }));

    let routeSegments = [];
    let segmentStart = includeCommute
      ? currentLocation
      : waypoints[0]?.location;

    for (let i = 0; i < waypoints.length; i += maxWaypoints) {
      const segmentWaypoints = waypoints.slice(i, i + maxWaypoints);
      const segmentEnd =
        i + maxWaypoints < waypoints.length
          ? segmentWaypoints.pop().location
          : waypoints[waypoints.length - 1]?.location;

      routeSegments.push({
        origin: segmentStart,
        destination: segmentEnd,
        waypoints: segmentWaypoints,
        optimizeWaypoints: optimizeRoute,
      });

      segmentStart = segmentEnd;
    }
    let fullDirectionsResult = null;

    const fetchSegmentDirections = async (index) => {
      if (index >= routeSegments.length) {
        if (fullDirectionsResult && !isTrimmed) {
          const { trimmedDirections, remainingOutlets } = trimWaypointsByTime(
            fullDirectionsResult,
            totalMinutes,
            selectedOutlets
          );
          if (remainingOutlets.length > 0) {
            const finalDirections = await fetchDirections(
              remainingOutlets,
              true,
              true
            );

            if (finalDirections) {
              const orderedOutlets = orderOutletsByLegs(
                finalDirections,
                remainingOutlets
              );
              setDirections(finalDirections);
              setFilteredOutlets(orderedOutlets);
            }
          }
        }
        return fullDirectionsResult;
      }

      return new Promise((resolve) => {
        directionsService.route(
          {
            origin: routeSegments[index].origin,
            destination: routeSegments[index].destination,
            waypoints: routeSegments[index].waypoints,
            optimizeWaypoints: routeSegments[index].optimizeWaypoints,
            travelMode: window.google.maps.TravelMode.DRIVING,
            drivingOptions: {
              departureTime: new Date(),
              trafficModel: "bestguess",
            },
          },
          (result, status) => {
            if (status === "OK" && result) {
              if (!fullDirectionsResult) {
                fullDirectionsResult = result;
              } else {
                fullDirectionsResult.routes[0].legs = [
                  ...fullDirectionsResult.routes[0].legs,
                  ...result.routes[0].legs,
                ];
                fullDirectionsResult.routes[0].overview_polyline =
                  result.routes[0].overview_polyline;
              }

              resolve(fetchSegmentDirections(index + 1));
            } else {
              console.error(
                `❌ Error fetching segment ${index}:`,
                status,
                result
              );
              resolve();
            }
          }
        );
      });
    };

    return await fetchSegmentDirections(0);
  };

  const trimWaypointsByTime = (
    directionsResult,
    totalMinutes,
    selectedOutlets
  ) => {
    let totalDuration = 0;
    let trimmedLegs = [];
    let userTime = totalMinutes * 60;
    let stopOverTime = 600;
    let remainingOutlets = [];

    for (let i = 0; i < directionsResult.routes[0].legs.length; i++) {
      let leg = directionsResult.routes[0].legs[i];
      let legDuration = leg.duration_in_traffic
        ? leg.duration_in_traffic.value
        : leg.duration.value;

      if (totalDuration + legDuration + stopOverTime > userTime) {
        break;
      }

      totalDuration += legDuration + stopOverTime;

      trimmedLegs.push(leg);
      remainingOutlets.push(selectedOutlets[i]);
    }

    let trimmedResult = { ...directionsResult };
    trimmedResult.routes[0].legs = trimmedLegs;

    return {
      trimmedDirections: trimmedResult,
      remainingOutlets: remainingOutlets.filter((item) => item !== undefined),
    };
  };

  const orderOutletsByLegs = (finalDirections, remainingOutlets) => {
    if (!finalDirections?.routes?.[0]?.legs) {
      return remainingOutlets;
    }

    const extractedLocations = [];
    finalDirections.routes[0].legs.forEach((leg) => {
      const loc = { lat: leg.end_location.lat(), lng: leg.end_location.lng() };
      if (
        !extractedLocations.some((e) => e.lat === loc.lat && e.lng === loc.lng)
      ) {
        extractedLocations.push(loc);
      }
    });

    const matchedIndexes = new Set();
    const orderedOutlets = extractedLocations
      .map((location) => {
        const matchedIndex = remainingOutlets.findIndex(
          (outlet, index) =>
            !matchedIndexes.has(index) &&
            Math.abs(parseFloat(outlet.Latitude) - location.lat) < 0.0005 &&
            Math.abs(parseFloat(outlet.Longitude) - location.lng) < 0.0005
        );

        if (matchedIndex !== -1) {
          matchedIndexes.add(matchedIndex);
          return remainingOutlets[matchedIndex];
        } else {
          return null;
        }
      })
      .filter(Boolean);

    return orderedOutlets;
  };

  const generateGoogleMapsLink = (directions) => {
    if (!directions || !directions.routes || directions.routes.length === 0)
      return null;

    const legs = directions.routes[0].legs;
    if (!legs || legs.length === 0) return null;

    const originLatLng = `${legs[0].start_location.lat()},${legs[0].start_location.lng()}`;
    const destinationLatLng = `${legs[
      legs.length - 1
    ].end_location.lat()},${legs[legs.length - 1].end_location.lng()}`;

    const waypointsLatLng = legs
      .slice(0, -2)
      .map((leg) => `${leg.end_location.lat()},${leg.end_location.lng()}`)
      .join("|");

    const googleMapsLink = `https://www.google.com/maps/dir/?api=1&origin=${originLatLng}&destination=${destinationLatLng}&travelmode=driving${
      waypointsLatLng ? `&waypoints=${waypointsLatLng}` : ""
    }`;

    return googleMapsLink;
  };

  useEffect(() => {
    if (directions && directions.routes?.length > 0) {
      const route = directions.routes[0].legs;
      const totalDistance = route.reduce(
        (sum, leg) => sum + leg.distance.value,
        0
      );
      const drivingDuration = route.reduce(
        (sum, leg) =>
          sum +
          (leg.duration_in_traffic
            ? leg.duration_in_traffic.value
            : leg.duration.value),
        0
      );
      const numWaypoints = route.length - 1;
      const stopDuration = numWaypoints * 600;
      const totalDuration = drivingDuration + stopDuration;
      const Mapslink = generateGoogleMapsLink(directions);
      const formatDuration = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return hours > 0 ? `${hours} hr ${minutes} min` : `${minutes} min`;
      };
      setGoogleMapsLink(Mapslink);
      setDistance(`${(totalDistance / 1000).toFixed(2)} km`);
      setDuration(`${formatDuration(drivingDuration)} (Real-time Driving)`);
      setEta(`${formatDuration(totalDuration)} (Including Stops)`);
    }
  }, [directions]);
  const [openSection, setOpenSection] = useState(null);
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [selectedLeg, setSelectedLeg] = useState(null);
  const handleOutletClick = (outlet, index) => {
    setSelectedOutlet(outlet);
    if (directions && directions.routes.length > 0) {
      const route = directions.routes[0];
      const leg = route.legs[index];
      setSelectedLeg(leg);
    }
    setOpenSection(null);
  };
  const toggleSection = (section) => {
    setOpenSection((prevSection) => (prevSection === section ? null : section));
  };

  const handleBack = () => {
    setDirections(null);
    setFilteredOutlets(null);
    setIsOutletRemoved(false);
    setIncludeCommute(false);
    setWayPoints([]);
    setSelectedLeg(null);
    setSelectedOutlet(null);
    setShowAllWaypoints(false);
  };
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "10px",
        height: isMediumDown ? "100vh" : "83vh",
        flexDirection: isMediumDown ? "column" : "row",
        marginTop: isMediumDown ? "" : "7%",
      }}
    >
      {isMediumDown && (
        <div
          style={{
            display: "flex",
            width: "95%",
            justifyContent: "space-between",
            backgroundColor: "#f0f0f0",
            padding: "2%",
            alignItems: "center",
          }}
        >
          <img
            fetchpriority="high"
            width={"100"}
            height={"44"}
            src="https://www.unibicfoods.com/wp-content/uploads/2022/12/logo_Red-1.png"
            className="attachment-full size-full wp-image-2014"
            alt=""
            srcSet="https://www.unibicfoods.com/wp-content/uploads/2022/12/logo_Red-1.png 500w, https://www.unibicfoods.com/wp-content/uploads/2022/12/logo_Red-1-300x133.png 300w"
            sizes="(max-width: 500px) 100vw, 500px"
          />
          <img
            alt="NoviroLogo"
            src={require("../assets/Noviro.png")}
            width={"100"}
            height={"35"}
          />
        </div>
      )}
      {isMediumDown && (
        <SlidingComponent1
          // setWayPoints={setWayPoints}
          includeCommute={includeCommute}
          selectAllOutlets={selectAllOutlets}
          hasSearched={hasSearched}
          setIsExpanded={setIsExpanded}
          IsExpanded={IsExpanded}
          currentAddress={currentAddress}
          filteredOutlets={filteredOutlets}
          setFilteredOutlets={setFilteredOutlets}
          // wayPoints={filteredOutlets}
          // currentOutlets={currentOutlets}
          // currentPage={currentPage}
          // totalPages={totalPages}
          // handlePageChange={handlePageChange}
          // handleGetRoute={handleGetRoute}
          // handleSelectOutlet={handleSelectOutlet}
          googleMapsLink={googleMapsLink}
          distance={distance}
          eta={eta}
          duration={duration}
          directions={directions}
          selectedLeg={selectedLeg}
          handleBack={handleBack}
          showAllWaypoints={showAllWaypoints}
          setShowAllWaypoints={setShowAllWaypoints}
          selectedOutlet={selectedOutlet}
          handleOutletClick={handleOutletClick}
          toggleSection={toggleSection}
          openSection={openSection}
          latitude={latitude}
          setLatitude={setLatitude}
          longitude={longitude}
          setLongitude={setLongitude}
          error={error}
          setError={setError}
          setUseCurrentLocation={setUseCurrentLocation}
          handleUpdateLocation={handleUpdateLocation}
          isLoading={isLoading}
          radius={radius}
          selectedSortOption={selectedSortOption}
          fetchDirections={fetchDirections}
          setIsOutletRemoved={setIsOutletRemoved}
          isOutletRemoved={isOutletRemoved}
        />
      )}

      <InfoContainer1
        includeCommute={includeCommute}
        setIncludeCommute={setIncludeCommute}
        errorRadius={errorRadius}
        currentLocation={currentLocation}
        currentAddress={currentAddress}
        filteredOutlets={filteredOutlets}
        setFilteredOutlets={setFilteredOutlets}
        directions={directions}
        fetchFilteredOutlets={fetchFilteredOutlets}
        setRadius={setRadius}
        radius={radius}
        // wayPoints={filteredOutlets}
        showAllWaypoints={showAllWaypoints}
        setShowAllWaypoints={setShowAllWaypoints}
        selectedSortOption={selectedSortOption}
        setSelectedSortOption={setSelectedSortOption}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        nonBilledFilters={nonBilledFilters}
        setNonBilledFilters={setNonBilledFilters}
        categorySizeFilters={categorySizeFilters}
        setCategorySizeFilters={setCategorySizeFilters}
        // currentOutlets={currentOutlets}
        // currentPage={currentPage}
        // totalPages={totalPages}
        // handlePageChange={handlePageChange}
        // handleGetRoute={handleGetRoute}
        // handleSelectOutlet={handleSelectOutlet}
        googleMapsLink={googleMapsLink}
        distance={distance}
        eta={eta}
        duration={duration}
        selectedLeg={selectedLeg}
        handleBack={handleBack}
        selectedOutlet={selectedOutlet}
        handleOutletClick={handleOutletClick}
        toggleSection={toggleSection}
        openSection={openSection}
        hasSearched={hasSearched}
        isLoading={isLoading}
        selectedBeat={selectedBeat}
        setSelectedBeat={setSelectedBeat}
        setSelectedTime={setSelectedTime}
        fetchDirections={fetchDirections}
        setIsOutletRemoved={setIsOutletRemoved}
        isOutletRemoved={isOutletRemoved}
      />
      <div style={{ flex: 2 }}>
        {currentLocation && (
          <MapContainer1
            currentLocation={currentLocation}
            filteredOutlets={filteredOutlets}
            directions={directions || null}
          />
        )}
      </div>
    </div>
  );
};

export default OutletMaps1;
