import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
const TravelSummaryTable = ({
  distance,
  duration,
  eta,
  filteredOutlets,
  totalPotentialSale,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {[
            { label: "Travel Distance", value: distance },
            {
              label: "Trip Completion Time",
              value: eta
            },
            { label: "Drive Time", value: duration },
            {
              label: "Visit Time",
              value: `${Math.floor((filteredOutlets?.length * 10) / 60)}h ${
                (filteredOutlets?.length * 10) % 60
              }m`,
            },
            { label: "Outlets Covered", value: `${filteredOutlets?.length}` },
            {
              label: "Potential Revenue",
              value: `₹${totalPotentialSale?.toLocaleString("en-IN")}`,
            },
          ].map((row, index) => (
            <TableRow key={index}>
              <TableCell style={{ fontFamily: "Roboto", fontWeight: 500 }}>
                {row.label}
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto", color: "rgba(0, 0, 0, 0.87)" }}
              >
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TravelSummaryTable;
