import React, { useEffect, useState } from "react";
import Navigator from "./Navigator";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

const App = () => {
  const [vh, setVh] = useState(window.innerHeight);
  const [vw, setVw] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setVh(window.innerHeight);
      setVw(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const isRotated = useMediaQuery("(orientation: landscape)");

  return (
    <div
      className="app"
      style={{
        // background:
        //   "linear-gradient(177deg,hsla(218, 36%, 93%, 0.64) 29%,hsla(0, 0%, 100%, 0.64) 150%)",
        display: isMediumDown ? "flex" : "",
        flexDirection: isMediumDown ? "column-reverse" : "",
        height: isMediumDown ? (isRotated ? "100vh" : `${vh}px`) : "",
        width: isRotated ? `${vw}px` : "",
      }}
    >
      <Navigator />
    </div>
  );
};

export default App;
