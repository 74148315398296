import {
  GoogleMap,
  MarkerF,
  InfoWindowF,
  DirectionsRenderer,
  useLoadScript,
} from "@react-google-maps/api";
import { useEffect, useState } from "react";
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";
import { Typography, Box } from "@mui/material";
const MapContainer1 = ({ currentLocation, filteredOutlets, directions }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  const [markers, setMarkers] = useState([]);
  const [directionsState, setDirectionsState] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [currentLocationState, setCurrentLocationState] = useState(null);
  const [mapCenter, setMapCenter] = useState(currentLocation);

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
    setMapCenter({ lat: marker.lat, lng: marker.lng });
  };
  useEffect(() => {
    if (isLoaded) {
      if (directions) {
        setDirectionsState(directions || null);
      } else {
        setDirectionsState(null);
      }
      const newMarkers = filteredOutlets?.map((outlet, index) => ({
        lat: parseFloat(outlet.Latitude),
        lng: parseFloat(outlet.Longitude),
        outletData: outlet,
        label: (index + 1).toString(),
        id: outlet.id || `outlet-${index}`,
      }));

      setMarkers((prevMarkers) =>
        JSON.stringify(prevMarkers) !== JSON.stringify(newMarkers)
          ? newMarkers
          : prevMarkers
      );
    }
  }, [isLoaded, filteredOutlets, directions]);
  useEffect(() => {
    if (selectedMarker) {
      const timer = setTimeout(() => {
        setSelectedMarker(null);
      }, 15000);
      return () => clearTimeout(timer);
    }
  }, [selectedMarker]);
  useEffect(() => {
    if (isLoaded) {
      if (currentLocation) {
        setCurrentLocationState(currentLocation);
      }
      // if (directions) {
      //   setCurrentLocationState(null);
      // }
    }
  }, [currentLocation, isLoaded, directions]);

  if (!isLoaded) return <p>Loading Maps...</p>;

  return (
    <GoogleMap
      center={mapCenter}
      zoom={20}
      mapContainerStyle={{ width: "100%", height: "100%" }}
    >
      {currentLocationState && (
        <MarkerF
          position={{
            lat: currentLocationState.lat,
            lng: currentLocationState.lng,
          }}
          icon={{
            url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
          }}
        />
      )}
      {markers?.map((marker, index) => (
        <MarkerF
          key={marker.id || index}
          position={{ lat: marker.lat, lng: marker.lng }}
          label={{
            text: marker.label,
            color: "white",
            fontWeight: "normal",
            fontSize: "16px",
          }}
          onClick={() => handleMarkerClick(marker)}
        />
      ))}
      {selectedMarker && selectedMarker.outletData && (
        <InfoWindowF
          position={{
            lat: selectedMarker.lat,
            lng: selectedMarker.lng,
          }}
          onCloseClick={() => setSelectedMarker(null)}
          options={{
            pixelOffset: new window.google.maps.Size(0, -40),
            zIndex: 1000,
            maxWidth: 240,
            boxStyle: {
              marginTop: "-60px",
            },
          }}
        >
          <Box sx={{ maxWidth: 240, zIndex: 1300 }}>
            <Typography
              fontWeight="bold"
              textAlign="center"
              color="black"
              gutterBottom
              fontSize={"19px"}
            >
              {selectedMarker.outletData?.Name?.toUpperCase()}
            </Typography>
            <Typography
              variant="body2"
              fontWeight="600"
              sx={{ display: "inline-flex", alignItems: "center", gap: 0.2 }}
            >
              <StorefrontTwoToneIcon fontSize="small" />
              OutletId:
              <Typography variant="body3" fontWeight="normal" component="span">
                {selectedMarker.outletData?.OutletId || "N/A"}
              </Typography>
            </Typography>
            <Typography variant="body2" fontWeight="600">
              📊 Order Probability:{" "}
              <Typography variant="body3" fontWeight="normal">
                {selectedMarker.outletData?.orderProbability || "N/A"}
              </Typography>
            </Typography>

            <Typography variant="body2" fontWeight="600">
              📈 Order Opportunity:{" "}
              <Typography variant="body3" fontWeight="normal">
                {selectedMarker.outletData?.orderOpportunity || "N/A"}
              </Typography>
            </Typography>

            <Typography variant="body2" fontWeight="600">
              🏷 Category:{" "}
              <Typography variant="body3" fontWeight="normal">
                {selectedMarker.outletData?.Category || "N/A"}
              </Typography>
            </Typography>
          </Box>
        </InfoWindowF>
      )}
      {directions && (
        <DirectionsRenderer
          directions={directionsState}
          options={{ suppressMarkers: true }}
        />
      )}
    </GoogleMap>
  );
};

export default MapContainer1;
