import React, { useState } from "react";
import {
  Modal,
  Box,
  Select,
  MenuItem,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CustomTimeModal = ({
  OpenTimeModal,
  handleClose,
  setSelectedTime,
  toggleCustomTimeModal,
  includeCommute,
  setIncludeCommute,
  hours,
  minutes,
  setHours,
  setMinutes,
}) => {
  const [tempHours, setTempHours] = useState(hours);
  const [tempMinutes, setTempMinutes] = useState(minutes);
  const [tempIncludeCommute, setTempIncludeCommute] = useState(includeCommute);

  const handleConfirm = () => {
    setHours(tempHours);
    setMinutes(tempMinutes);
    setIncludeCommute(tempIncludeCommute);
    setSelectedTime({ hours: tempHours, minutes: tempMinutes });
    handleClose();
  };

  return (
    <Modal open={OpenTimeModal} onClose={handleClose}>
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "300px",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px",
          borderRadius: "8px",
          background: "white",
          zIndex: "1000",
          padding: "12px",
          border: "1px solid rgb(204, 204, 204)",
          textAlign: "center",
          fontFamily: "Roboto",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <CloseIcon
            onClick={toggleCustomTimeModal}
            style={{
              color: "white",
              backgroundColor: "rgb(11 9 9 / 82%)",
              fontSize: "22px",
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: "#222",
              },
              "&:active": {
                backgroundColor: "#444",
              },
              border: "none",
              cursor: "pointer",
              alignContent: "flex-end",
            }}
          />
        </Box>
        <Typography
          sx={{
            mb: 2,
            fontSize: "20px",
            fontWeight: "bold",
            paddingTop: "0px",
          }}
        >
          Pick a Time
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "center",
          }}
        >
          <Select
            value={tempHours}
            onChange={(e) => setTempHours(e.target.value)}
            sx={{
              width: "110px",
              height: "50px",
              backgroundColor: "rgb(240, 240, 240)",
            }}
          >
            {[...Array(9).keys()].map((h) => (
              <MenuItem key={h} value={h}>
                {h} hr
              </MenuItem>
            ))}
          </Select>
          <Select
            value={tempMinutes}
            onChange={(e) => setTempMinutes(e.target.value)}
            sx={{
              width: "110px",
              height: "50px",
              backgroundColor: "rgb(240, 240, 240)",
            }}
          >
            {[0, 15, 30, 45].map((m) => (
              <MenuItem key={m} value={m}>
                {m} min
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box display="flex" justifyContent="center" paddingY="10px">
          <FormControlLabel
            control={
              <Checkbox
                checked={tempIncludeCommute}
                onChange={(e) => setTempIncludeCommute(e.target.checked)}
                sx={{
                  transform: "scale(0.8)",
                  "&.Mui-checked": { color: "blue" },
                }}
              />
            }
            label="Include Commute"
            sx={{ "& .MuiTypography-root": { fontSize: "14px" } }}
          />
        </Box>

        <Button
          sx={{
            color: "white",
            backgroundColor: "rgb(11 9 9 / 82%)",
            fontSize: "16px",
            borderRadius: "5px",
            "&:hover": { backgroundColor: "#222" },
            padding: "8px 16px",
            height: "35px",
          }}
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </Box>
    </Modal>
  );
};

export default CustomTimeModal;
