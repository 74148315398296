import { useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Box,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CustomTimeModal from "./customTimeModal";
import TravelSummaryTable from "./travelSummaryTable";
const InfoContainer1 = ({
  includeCommute,
  setIncludeCommute,
  errorRadius,
  currentLocation,
  currentAddress,
  directions,
  fetchFilteredOutlets,
  radius,
  setRadius,
  selectedSortOption,
  setSelectedSortOption,
  selectedFilters,
  setSelectedFilters,
  nonBilledFilters,
  setNonBilledFilters,
  categorySizeFilters,
  setCategorySizeFilters,
  googleMapsLink,
  distance,
  eta,
  duration,
  selectedLeg,
  handleBack,
  selectedOutlet,
  handleOutletClick,
  toggleSection,
  openSection,
  isLoading,
  hasSearched,
  selectedBeat,
  setSelectedBeat,
  setSelectedTime,
  showAllWaypoints,
  setShowAllWaypoints,
  filteredOutlets,
  setFilteredOutlets,
  isOutletRemoved,
  setIsOutletRemoved,
  fetchDirections,
}) => {
  const removeWaypoint = (index) => {
    setFilteredOutlets(filteredOutlets.filter((_, i) => i !== index));
    setIsOutletRemoved(true);
  };
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCustomTimeModal, setIsCustomTimeModal] = useState(false);
  const [timeConstraint, setTimeConstraint] = useState("wholeDay");

  let beats = sessionStorage.getItem("Beats")?.split(",") || [];
  beats = beats.map((beat) =>
    beat
      .trim()
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
  );
  useEffect(() => {
    if (beats.length > 0 && !selectedBeat) {
      setSelectedBeat(beats[0]);
    }
  }, [beats]);
  const Marker = ({ number }) => {
    return (
      <svg
        width="30"
        height="40"
        viewBox="-3 -3 30 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 30C9.5 25.5 0 16.5 0 10C0 4.477 4.477 0 10 0H14C19.523 0 24 4.477 24 10C24 16.5 14.5 25.5 12 30Z"
          fill="red"
        />
        <text
          x="40%"
          y="25%"
          fontSize="13"
          fill="white"
          textAnchor="middle"
          dy=".3em"
          fontWeight="bold"
        >
          {number}
        </text>
      </svg>
    );
  };

  const totalPotentialSale =
    filteredOutlets &&
    filteredOutlets?.reduce(
      (sum, outlet) => sum + (outlet?.averageSale || 0),
      0
    );

  const toggleModal = () => setIsModalOpen((prev) => !prev);
  const toggleCustomTimeModal = () => setIsCustomTimeModal((prev) => !prev);

  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const handleSort = (e) => {
    setSelectedSortOption(e.target.value);
  };
  const handleBeatChange = (e) => {
    setSelectedBeat(e.target.value);
  };
  const handleFilterChange = (filter) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: !prevFilters[filter],
    }));
  };
  const handleNonBilledFilterChange = (days) => {
    setNonBilledFilters((prevFilters) => {
      if (days === "ALL") {
        const newState = Object.keys(prevFilters).reduce((acc, key) => {
          acc[key] = {
            ...prevFilters[key],
            isSelected: !prevFilters["ALL"].isSelected,
          };
          return acc;
        }, {});
        return newState;
      } else {
        const newFilters = {
          ...prevFilters,
          [days]: {
            ...prevFilters[days],
            isSelected: !prevFilters[days].isSelected,
          },
        };
        const allSelected = Object.keys(newFilters)
          .filter((key) => key !== "ALL")
          .every((key) => newFilters[key].isSelected);

        newFilters["ALL"] = { ...newFilters["ALL"], isSelected: allSelected };
        return newFilters;
      }
    });
  };

  const handleCategorySizeFilterChange = (range) => {
    setCategorySizeFilters((prevFilters) => {
      if (range === "ALL") {
        const newState = Object.keys(prevFilters).reduce((acc, key) => {
          acc[key] = !prevFilters["ALL"];
          return acc;
        }, {});
        return newState;
      } else {
        const newFilters = { ...prevFilters, [range]: !prevFilters[range] };
        const allSelected = Object.keys(newFilters)
          .filter((key) => key !== "ALL")
          .every((key) => newFilters[key]);
        newFilters["ALL"] = allSelected;
        return newFilters;
      }
    });
  };

  const handleOptionChange = (event) => {
    const value = event.target.value;
    if (value === "wholeDay") {
      setTimeConstraint("wholeDay");
      setSelectedTime({ hours: 8, minutes: 0 });
    } else {
      setTimeConstraint("custom");
      setIsCustomTimeModal(true);
    }
  };

  const renderWaypoints = () => {
    if (filteredOutlets.length <= 5 || showAllWaypoints) {
      return filteredOutlets.map((outlet, index) => (
        <div
          key={outlet.Name}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "5px",
          }}
        >
          {index < filteredOutlets.length - 1 ? (
            <div style={{ display: "flex" }}>
              <Marker number={index + 1} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <RemoveCircleIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => removeWaypoint(index)}
                />
                <img alt="" src={require("../assets/threedots.png")} />
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <Marker number={filteredOutlets.length} />
              <RemoveCircleIcon
                style={{ cursor: "pointer" }}
                onClick={() => removeWaypoint(filteredOutlets.length - 1)}
              />
            </div>
          )}
          <div
            style={{
              width: "250px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              height: "fit-content",
              backgroundColor: "lightgray",
              padding: "1% 2%",
              borderRadius: "11px",
              cursor: "pointer",
            }}
            onClick={() => handleOutletClick(outlet, index)}
          >
            {outlet?.Name}
          </div>
        </div>
      ));
    } else {
      return (
        <>
          {filteredOutlets.slice(0, 2).map((outlet, index) => (
            <div style={{ display: "flex" }}>
              <Marker number={index + 1} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <RemoveCircleIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => removeWaypoint(index)}
                />
                <img alt="" src={require("../assets/threedots.png")} />
              </div>
              <div
                style={{
                  width: "250px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  height: "fit-content",
                  backgroundColor: "lightgray",
                  padding: "1% 2%",
                  borderRadius: "11px",
                  cursor: "pointer",
                  marginLeft: "1%",
                }}
                onClick={() => handleOutletClick(outlet, index)}
              >
                {outlet?.Name}
              </div>
            </div>
          ))}
          <div
            style={{
              borderRadius: "11px",
              cursor: "pointer",
              display: "flex",
              alignItems: "flex-start",
              gap: "5px",
              fontWeight: 600,
              color: "rgb(11 134 153)",
              marginLeft: "30px",
            }}
            onClick={() => setShowAllWaypoints(true)}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FiberManualRecordIcon />
              <img alt="" src={require("../assets/threedots.png")} />
            </div>
            {filteredOutlets.length - 3} stops...
          </div>
          <div
            key={filteredOutlets[filteredOutlets.length - 1]?.Name}
            style={{ display: "flex", gap: "5px", alignItems: "flex-start" }}
          >
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <Marker number={filteredOutlets.length} />
              <RemoveCircleIcon
                style={{ cursor: "pointer" }}
                onClick={() => removeWaypoint(filteredOutlets.length - 1)}
              />
            </div>
            <div
              style={{
                width: "250px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                height: "fit-content",
                backgroundColor: "lightgray",
                padding: "1% 2%",
                borderRadius: "11px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleOutletClick(
                  filteredOutlets[filteredOutlets.length - 1],
                  filteredOutlets.length - 1
                )
              }
            >
              {filteredOutlets[filteredOutlets.length - 1]?.Name}
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div
      style={{
        flex: isMediumDown ? "" : 1,
        padding: isMediumDown ? "0px" : "0 20px",
        maxHeight: isMediumDown ? "none" : "100vh",
        overflowY: isMediumDown ? "none" : "auto",
        paddingBottom: isMediumDown ? "8px" : "",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            fontWeight: "bolder",
            fontFamily: "Roboto",
            fontSize: isMediumDown ? "large" : "x-large",
          }}
        >
          {" "}
          Plan Your Visits
        </Typography>
      </div>
      {currentLocation ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: isMediumDown ? "0px" : "10px",
            marginTop: isMediumDown ? "0px" : "7px",
          }}
        >
          {!isMediumDown && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <div
                style={{
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontWeight: "bold",
                  paddingBottom: "8px",
                  paddingTop: "8px",
                }}
              >
                Your Location
              </div>

              {/* Address on Second Line */}
              <div style={{ fontFamily: "Roboto", fontSize: "16px" }}>
                {currentAddress}
              </div>

              {/* Divider Below the Address */}
              <hr
                style={{
                  width: "100%",
                  border: "1px solid #ccc",
                  marginTop: "8px",
                }}
              />
            </div>
          )}

          {!directions && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontFamily: "Roboto",
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: isMediumDown ? "" : "2px",
                  }}
                >
                  <Typography
                    variant="subtitle4"
                    color="black"
                    fontFamily="Roboto"
                    fontWeight={isMediumDown ? "100" : "bolder"}
                    fontSize={isMediumDown ? "13px" : "15px"}
                  >
                    Select Your Beat for the day :
                  </Typography>
                  <select
                    value={selectedBeat}
                    onChange={handleBeatChange}
                    style={{
                      border: "0px",
                      backgroundColor: "#f0f0f0",
                      fontFamily: "Roboto",
                      fontSize: "13px",
                      height: "32px",
                    }}
                  >
                    {beats?.map((beat, index) => (
                      <option key={index} value={beat}>
                        {beat}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  style={{
                    fontFamily: "Roboto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="subtitle4"
                    color="black"
                    fontFamily="Roboto"
                    fontSize={isMediumDown ? "13px" : "15px"}
                    fontWeight={isMediumDown ? "100" : "bolder"}
                  >
                    Set Your Search Radius (km)
                  </Typography>
                  <input
                    type="number"
                    value={radius}
                    style={{
                      width: isMediumDown ? "99%" : "99%",
                      height: isMediumDown ? "30px" : "32px",
                      backgroundColor: "rgb(240, 240, 240)",
                      border: "0",
                      fontSize: isMediumDown ? "13px" : "",
                    }}
                    onChange={(e) => setRadius(e.target.value)}
                  />
                </div>
                {errorRadius && (
                  <div
                    style={{
                      color: "#ff0000",
                      fontSize: "10px",
                      marginTop: "1px",
                      fontWeight: "normal",
                    }}
                  >
                    {errorRadius}
                  </div>
                )}
              </div>
              {isMediumDown && (
                <div>
                  <Typography
                    variant="subtitle4"
                    color="black"
                    fontFamily="Roboto"
                    fontSize="13px"
                    fontWeight={100}
                  >
                    Select how you'd like to schedule your visit:
                  </Typography>

                  {/* Toggle Buttons for Time Constraint */}
                  <ToggleButtonGroup
                    value={timeConstraint}
                    exclusive
                    onChange={handleOptionChange}
                    sx={{
                      display: "flex",
                      paddingBottom: "5px",
                    }}
                  >
                    <ToggleButton
                      value="wholeDay"
                      sx={{
                        fontSize: "10px",
                        width: "50%",
                        height: "29px",
                      }}
                    >
                      Whole Day
                    </ToggleButton>
                    <ToggleButton
                      value="custom"
                      sx={{
                        fontSize: "10px",
                        width: "50%",
                        height: "29px",
                      }}
                    >
                      Custom Time
                    </ToggleButton>
                  </ToggleButtonGroup>

                  {timeConstraint === "custom" && isCustomTimeModal && (
                    <CustomTimeModal
                      OpenTimeModal={isCustomTimeModal}
                      handleClose={() => setIsCustomTimeModal(false)}
                      toggleCustomTimeModal={toggleCustomTimeModal}
                      includeCommute={includeCommute}
                      setIncludeCommute={setIncludeCommute}
                      setSelectedTime={setSelectedTime}
                      hours={hours}
                      setHours={setHours}
                      minutes={minutes}
                      setMinutes={setMinutes}
                    />
                  )}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {isMediumDown ? (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBottom: "0px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "50%",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          variant="subtitle4"
                          color="black"
                          fontFamily="Roboto"
                          fontSize="13px"
                          fontWeight={100}
                        >
                          Revenue Potential{" "}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          width: "50%",
                        }}
                      >
                        <Typography
                          variant="subtitle4"
                          color="black"
                          fontFamily="Roboto"
                          fontSize="13px"
                          fontWeight={100}
                        >
                          Outlet Filters{" "}
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBottom: "4px",
                      }}
                    >
                      <select
                        value={selectedSortOption}
                        onChange={handleSort}
                        style={{
                          border: "0px",
                          backgroundColor: "#f0f0f0",
                          fontFamily: "Roboto",
                          fontSize: "13px",
                          padding: "2%",
                          width: "45%",
                          textAlign: "center",
                          // height: "13px",
                        }}
                      >
                        <option value="probability">Likelihood</option>
                        <option value="opportunity">Opportunity</option>
                      </select>
                      <div
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#f0f0f0",
                          fontSize: "13px",
                          padding: "2%",
                          width: "45%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "17px",
                        }}
                        onClick={toggleModal}
                      >
                        Filters
                      </div>
                    </div>

                    <div
                      style={{
                        width: "100%",
                        height: "28px",
                        padding: "1% 0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgb(11 9 9 / 82%)",
                        fontSize: "14px",
                        cursor: "pointer",
                        borderRadius: "5px",
                        color: "white",
                        fontWeight: "500",
                      }}
                      onClick={fetchFilteredOutlets}
                    >
                      GET ROUTE
                    </div>

                    {isModalOpen && (
                      <div
                        style={{
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "300px",
                          background: "white",
                          border: "1px solid #ccc",
                          borderRadius: "8px",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          zIndex: 1000,
                          padding: "18px",
                        }}
                      >
                        <Box
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <CloseIcon
                            onClick={toggleModal}
                            style={{
                              color: "white",
                              backgroundColor: "rgb(11 9 9 / 82%)",
                              fontSize: "22px",
                              borderRadius: "5px",
                              "&:hover": {
                                backgroundColor: "#222",
                              },
                              "&:active": {
                                backgroundColor: "#444",
                              },
                              border: "none",
                              cursor: "pointer",
                              alignContent: "flex-end",
                            }}
                          />
                        </Box>
                        <div
                          style={{
                            marginTop: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              mb: 2,
                              fontSize: "15px",
                              fontWeight: "bold",
                              paddingTop: "0px",
                              paddingBottom: "10px",
                            }}
                          >
                            Select Filters
                          </div>
                        </div>
                        {Object.keys(selectedFilters).map((filter) => (
                          <label
                            key={filter}
                            style={{
                              display: "flex",
                              marginBottom: "10px",
                              fontSize: "14px",
                              fontFamily: "Roboto",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={selectedFilters[filter]}
                              onChange={() => handleFilterChange(filter)}
                              style={{ width: "12px", height: "12px" }}
                            />
                            {filter.charAt(0).toUpperCase() + filter.slice(1)}
                          </label>
                        ))}
                        {selectedFilters.categorySize && (
                          <div
                            style={{
                              marginTop: "10px",
                              backgroundColor: "#f0f0f0",
                              borderRadius: "10px",
                              padding: "10px",
                            }}
                          >
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {Object.keys(categorySizeFilters).map((range) => (
                                <label
                                  key={range}
                                  style={{
                                    marginBottom: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={categorySizeFilters[range]}
                                    onChange={() =>
                                      handleCategorySizeFilterChange(range)
                                    }
                                    style={{ width: "12px", height: "12px" }}
                                  />
                                  <span
                                    style={{
                                      margin: "0px 8px",
                                      fontSize: "13px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {range}
                                  </span>
                                </label>
                              ))}
                            </div>
                          </div>
                        )}
                        {selectedFilters.nonBilled && (
                          <div
                            style={{
                              marginTop: "10px",
                              backgroundColor: "#f0f0f0",
                              borderRadius: "10px",
                              padding: "10px",
                            }}
                          >
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {Object.keys(nonBilledFilters).map((days) => (
                                <label
                                  key={days}
                                  style={{
                                    marginBottom: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={nonBilledFilters[days].isSelected}
                                    onChange={() =>
                                      handleNonBilledFilterChange(days)
                                    }
                                    style={{ width: "12px", height: "12px" }}
                                  />
                                  <span
                                    style={{
                                      margin: "0px 8px",
                                      fontSize: "13px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {days}
                                  </span>
                                </label>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "bolder",
                          fontSize: "15px",
                        }}
                      >
                        Filters
                        {Object.keys(selectedFilters).map((filter) => (
                          <label
                            key={filter}
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                              fontFamily: "Roboto",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={selectedFilters[filter]}
                              onChange={() => handleFilterChange(filter)}
                              style={{ width: "12px", height: "12px" }}
                            />
                            {filter.charAt(0).toUpperCase() + filter.slice(1)}
                          </label>
                        ))}
                      </div>
                      {selectedFilters.categorySize && (
                        <div
                          style={{
                            marginTop: "10px",
                            padding: "10px",
                            backgroundColor: "#f0f0f0",
                            borderRadius: "10px",
                          }}
                        >
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {Object.keys(categorySizeFilters).map((range) => (
                              <label
                                key={range}
                                style={{
                                  marginBottom: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={categorySizeFilters[range]}
                                  onChange={() =>
                                    handleCategorySizeFilterChange(range)
                                  }
                                  style={{ width: "12px", height: "12px" }}
                                />
                                <span
                                  style={{
                                    margin: "0px 8px",
                                    fontSize: "13px",
                                    fontFamily: "Roboto",
                                  }}
                                >
                                  {range}
                                </span>
                              </label>
                            ))}
                          </div>
                        </div>
                      )}
                      {selectedFilters.nonBilled && (
                        <div
                          style={{
                            marginTop: "10px",
                            backgroundColor: "#f0f0f0",
                            borderRadius: "10px",
                            padding: "10px",
                          }}
                        >
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {Object.keys(nonBilledFilters).map((days) => (
                              <label
                                key={days}
                                style={{
                                  marginBottom: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={nonBilledFilters[days].isSelected}
                                  onChange={() =>
                                    handleNonBilledFilterChange(days)
                                  }
                                  style={{ width: "12px", height: "12px" }}
                                />
                                <span
                                  style={{
                                    margin: "0px 8px",
                                    fontSize: "13px",
                                    fontFamily: "Roboto",
                                  }}
                                >
                                  {days}
                                </span>
                              </label>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>

                    <div>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          paddingTop: "10px",
                          fontWeight: "bolder",
                        }}
                      >
                        Sort By
                      </Typography>
                      <select
                        value={selectedSortOption}
                        onChange={handleSort}
                        style={{
                          border: "0px",
                          backgroundColor: "#f0f0f0",
                          width: "100%",
                          height: "32px",
                        }}
                      >
                        <option value="probability">Likelihood</option>
                        <option value="opportunity">Opportunity</option>
                      </select>
                    </div>
                    <div>
                      <Typography
                        variant="subtitle4"
                        color="black"
                        fontFamily="Roboto"
                        fontSize="15px"
                        fontWeight="bolder"
                      >
                        Select how you'd like to schedule your visit:
                      </Typography>

                      <ToggleButtonGroup
                        value={timeConstraint}
                        exclusive
                        onChange={handleOptionChange}
                        sx={{
                          display: "flex",
                          paddingBottom: "5px",
                        }}
                      >
                        <ToggleButton
                          value="wholeDay"
                          sx={{
                            fontSize: "10px",
                            width: "50%",
                          }}
                        >
                          Whole Day
                        </ToggleButton>
                        <ToggleButton
                          value="custom"
                          sx={{
                            fontSize: "10px",
                            width: "50%",
                          }}
                        >
                          Custom Time
                        </ToggleButton>
                      </ToggleButtonGroup>

                      {timeConstraint === "custom" && isCustomTimeModal && (
                        <CustomTimeModal
                          OpenTimeModal={isCustomTimeModal}
                          handleClose={() => setIsCustomTimeModal(false)}
                          toggleCustomTimeModal={toggleCustomTimeModal}
                          includeCommute={includeCommute}
                          setIncludeCommute={setIncludeCommute}
                          setSelectedTime={setSelectedTime}
                          hours={hours}
                          setHours={setHours}
                          minutes={minutes}
                          setMinutes={setMinutes}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
              {!isMediumDown && (
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(11 9 9 / 82%)",
                    fontSize: "16px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                    fontWeight: "400",
                    height: "36px",
                  }}
                  onClick={fetchFilteredOutlets}
                >
                  Get Route
                </div>
              )}
            </>
          )}

          {!isMediumDown ? (
            directions ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "2%",
                  }}
                >
                  <div>Route Overview</div>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      padding: "2%",
                    }}
                  >
                    {isOutletRemoved && (
                      <button
                        style={{
                          border: "0px",
                          borderRadius: "5px",
                          fontFamily: "Roboto",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                          fontWeight: "bolder",
                          width: "80px",
                        }}
                        onClick={() => {
                          fetchDirections(filteredOutlets);
                        }}
                      >
                        Update Route
                      </button>
                    )}
                    <button
                      style={{
                        padding: "2%",
                        border: "0px",
                        borderRadius: "5px",
                        height: "23px",
                        fontWeight: "bolder",
                        width: "70px",
                      }}
                      onClick={() => {
                        handleBack();
                      }}
                    >
                      back
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: "2%",
                    gap: "8px",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "5px",
                      // marginLeft:"30px"

                    }}
                  >
                    {filteredOutlets.length > 0 && includeCommute && (
                    <div style={{display:"flex"}}>
                      <img
                        src="http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                        alt=""
                        style={{width:"30px",height:"30px"}}
                      />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <RemoveCircleIcon />
                        <img alt="" src={require("../assets/threedots.png")} />
                      </div>
                    </div>
                  )}
                    {includeCommute && (
                      <div
                        style={{
                          width: "250px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          height: "fit-content",
                          backgroundColor: "lightgray",
                          padding: "1% 2%",
                          borderRadius: "11px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Your Location
                      </div>
                    )}
                  </div>

                  {/* {filteredOutlets.map((outlet, index) => (
                    <div
                      key={outlet.Name}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "5px",
                      }}
                    >
                      {index < filteredOutlets.length - 1 ? (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <FiberManualRecordIcon />
                          <img
                            alt=""
                            src={require("../assets/threedots.png")}
                          />
                        </div>
                      ) : (
                        <PlaceIcon />
                      )}
                      <div
                        style={{
                          width: "250px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          height: "fit-content",
                          backgroundColor: "lightgray",
                          padding: "1% 2%",
                          borderRadius: "11px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOutletClick(outlet, index)}
                      >
                        {outlet?.Name}
                      </div>
                    </div>
                  ))} */}
                  {renderWaypoints()}
                </div>
                <TravelSummaryTable
                  distance={distance}
                  duration={duration}
                  eta={eta}
                  filteredOutlets={filteredOutlets}
                  totalPotentialSale={totalPotentialSale}
                />

                {selectedOutlet && (
                  <div style={{ marginTop: "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                        borderRadius: "4px",
                      }}
                      onClick={() => toggleSection("table")}
                    >
                      <h3>{selectedOutlet.Name}</h3>
                      {openSection === "table" ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </div>
                    {openSection === "table" && (
                      <TableContainer component={Paper}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>Category Size</TableCell>
                              <TableCell>{selectedOutlet.Category}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Last Bill Date</TableCell>
                              <TableCell>
                                {new Date(
                                  selectedOutlet.Last_Order_Date
                                ).toLocaleDateString()}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Bill Cuts (12 months)</TableCell>
                              <TableCell>
                                {selectedOutlet.Total_Orders}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Bill Value (12 months)</TableCell>
                              <TableCell>
                                ₹
                                {parseFloat(
                                  selectedOutlet.Total_Sales
                                ).toLocaleString("en-IN")}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>No of Days of No Billing</TableCell>
                              <TableCell>
                                {Math.floor(
                                  (new Date() -
                                    new Date(selectedOutlet.Last_Order_Date)) /
                                    (1000 * 60 * 60 * 24)
                                )}{" "}
                                days
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </div>
                )}
                {selectedLeg && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                        borderRadius: "4px",
                      }}
                      onClick={() => toggleSection("steps")}
                    >
                      <h3>Steps to Reach {selectedOutlet?.Name}</h3>
                      {openSection === "steps" ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </div>
                    {openSection === "steps" && (
                      <div>
                        <div>
                          <strong>Start Address:</strong>{" "}
                          {selectedLeg.start_address}
                        </div>
                        <div>
                          <strong>Destination:</strong>{" "}
                          {selectedLeg.end_address}
                        </div>
                        <ul>
                          {selectedLeg.steps.map((step, idx) => (
                            <li
                              key={idx}
                              dangerouslySetInnerHTML={{
                                __html: step.instructions,
                              }}
                            ></li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                )}
                {googleMapsLink && (
                  <a
                    href={googleMapsLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "underline",
                      color: "rgb(11 134 153)",
                      fontWeight: "bold",
                    }}
                  >
                    Open in Google Maps
                  </a>
                )}
              </div>
            ) : isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <CircularProgress size="2rem" sx={{ color: "red" }} />
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
      ) : (
        <p>{"Fetching your location..."}</p>
      )}
    </div>
  );
};
export default InfoContainer1;
