import { Box, CircularProgress, Container, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import TravelSummaryTable from "./travelSummaryTable";

const SlidingComponent1 = ({
  // setWayPoints,
  // selectAllOutlets,
  hasSearched,
  setIsExpanded,
  IsExpanded,
  currentAddress,
  filteredOutlets,
  setFilteredOutlets,
  // currentOutlets,
  // currentPage,
  // totalPages,
  // handlePageChange,
  // wayPoints,
  // handleGetRoute,
  // handleSelectOutlet,
  googleMapsLink,
  distance,
  eta,
  duration,
  directions,
  selectedLeg,
  handleBack,
  selectedOutlet,
  handleOutletClick,
  toggleSection,
  openSection,
  latitude,
  setLatitude,
  longitude,
  setLongitude,
  error,
  setError,
  setUseCurrentLocation,
  handleUpdateLocation,
  isLoading,
  selectedSortOption,
  includeCommute,
  showAllWaypoints,
  setShowAllWaypoints,
  isOutletRemoved,
  setIsOutletRemoved,
  fetchDirections,
}) => {
  const removeWaypoint = (index) => {
    setFilteredOutlets(filteredOutlets.filter((_, i) => i !== index));
    setIsOutletRemoved(true);
  };
  const [loading, setLoading] = useState(false);
  const handleUpdateRoute = async () => {
    setLoading(true);
    await fetchDirections(filteredOutlets);
    setLoading(false);
  };

  useEffect(() => {}, [isOutletRemoved, filteredOutlets]);
  const maxHeight = window.innerHeight * 0.8;
  const minHeight = 100;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const totalPotentialSale =
    filteredOutlets &&
    filteredOutlets?.reduce(
      (sum, outlet) => sum + (outlet?.averageSale || 0),
      0
    );
  useEffect(() => {
    if (
      (filteredOutlets?.length > 0 ||
        (filteredOutlets?.length > 0 && selectedSortOption) ||
        filteredOutlets?.length === 0 ||
        isLoading) &&
      hasSearched
    ) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [filteredOutlets, isLoading, hasSearched, selectedSortOption]);

  const Marker = ({ number }) => {
    return (
      <svg
        width="30"
        height="40"
        viewBox="-3 -3 30 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 30C9.5 25.5 0 16.5 0 10C0 4.477 4.477 0 10 0H14C19.523 0 24 4.477 24 10C24 16.5 14.5 25.5 12 30Z"
          fill="red"
        />
        <text
          x="40%"
          y="25%"
          fontSize="13"
          fill="white"
          textAnchor="middle"
          dy=".3em"
          fontWeight="bold"
        >
          {number}
        </text>
      </svg>
    );
  };

  const renderWaypoints = () => {
    if (filteredOutlets.length <= 5 || showAllWaypoints) {
      return filteredOutlets.map((outlet, index) => (
        <div
          key={outlet.Name}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "5px",
          }}
        >
          {index < filteredOutlets.length - 1 ? (
            <div style={{ display: "flex" }}>
              <Marker number={index + 1} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <RemoveCircleIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => removeWaypoint(index)}
                />
                <img alt="" src={require("../assets/threedots.png")} />
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <Marker number={filteredOutlets.length} />
              <RemoveCircleIcon
                style={{ cursor: "pointer" }}
                onClick={() => removeWaypoint(filteredOutlets.length - 1)}
              />
            </div>
          )}
          <div
            style={{
              width: "250px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              height: "fit-content",
              backgroundColor: "lightgray",
              padding: "1% 2%",
              borderRadius: "11px",
              cursor: "pointer",
            }}
            onClick={() => handleOutletClick(outlet, index)}
          >
            {outlet?.Name}
          </div>
        </div>
      ));
    } else {
      return (
        <>
          {filteredOutlets.slice(0, 2).map((outlet, index) => (
            <div style={{ display: "flex" }}>
              <Marker number={index + 1} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <RemoveCircleIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => removeWaypoint(index)}
                />
                <img alt="" src={require("../assets/threedots.png")} />
              </div>
              <div
                style={{
                  width: "250px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  height: "fit-content",
                  backgroundColor: "lightgray",
                  padding: "1% 2%",
                  borderRadius: "11px",
                  cursor: "pointer",
                  marginLeft: "1%",
                }}
                onClick={() => handleOutletClick(outlet, index)}
              >
                {outlet?.Name}
              </div>
            </div>
          ))}
          <div
            style={{
              borderRadius: "11px",
              cursor: "pointer",
              display: "flex",
              alignItems: "flex-start",
              gap: "5px",
              fontWeight: 600,
              color: "rgb(11 134 153)",
              marginLeft: "30px",
            }}
            onClick={() => setShowAllWaypoints(true)}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FiberManualRecordIcon />
              <img alt="" src={require("../assets/threedots.png")} />
            </div>
            {filteredOutlets.length - 3} stops...
          </div>
          <div
            key={filteredOutlets[filteredOutlets.length - 1]?.Name}
            style={{ display: "flex", gap: "5px", alignItems: "flex-start" }}
          >
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <Marker number={filteredOutlets.length} />
              <RemoveCircleIcon
                style={{ cursor: "pointer" }}
                onClick={() => removeWaypoint(filteredOutlets.length - 1)}
              />
            </div>
            <div
              style={{
                width: "250px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                height: "fit-content",
                backgroundColor: "lightgray",
                padding: "1% 2%",
                borderRadius: "11px",
                cursor: "pointer",
              }}
              onClick={() =>
                handleOutletClick(
                  filteredOutlets[filteredOutlets.length - 1],
                  filteredOutlets.length - 1
                )
              }
            >
              {filteredOutlets[filteredOutlets.length - 1]?.Name}
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <Container
      disableGutters
      sx={{
        position: "fixed",
        bottom: "50px",
        left: 0,
        right: 0,
        backgroundColor: "white",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "30px 30px 0 0",
        height: IsExpanded ? `${maxHeight}px` : `${minHeight}px`,
        transition: "height 0.3s ease-in-out",
        zIndex: 50,
        overflow: "auto",
        scrollbarWidth: "none",
        padding: "10px",
        border: "1px solid #80808038",
      }}
    >
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 999,
          }}
        >
          <CircularProgress size={60} sx={{ color: "red" }} />
        </Box>
      )}
      <Box sx={{ height: "80%" }}>
        <>
          {directions ? (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: isOutletRemoved ? "" : "space-between",
                  gap: isOutletRemoved ? "15%" : "",
                  padding: "1%",
                }}
              >
                <div style={{ fontFamily: "Roboto" }}>Route Overview</div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  {isOutletRemoved && (
                    <button
                      style={{
                        padding: "4%",
                        border: "0px",
                        borderRadius: "5px",
                        fontFamily: "Roboto",
                        whiteSpace: "nowrap",
                      }}
                      onClick={handleUpdateRoute}
                      disabled={loading}
                    >
                      {"Update Route"}
                    </button>
                  )}
                  <button
                    style={{
                      padding: "4%",
                      border: "0px",
                      borderRadius: "5px",
                      fontFamily: "Roboto",
                    }}
                    onClick={() => {
                      handleBack();
                    }}
                  >
                    back
                  </button>
                  <button
                    onClick={() => setIsExpanded(!IsExpanded)}
                    style={{
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      height: "24px",
                    }}
                  >
                    {IsExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                  </button>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  padding: "2%",
                  gap: "8px",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "5px",
                    // marginLeft: "30px",
                  }}
                >
                  {filteredOutlets.length > 0 && includeCommute && (
                    <div style={{ display: "flex" }}>
                      <img
                        src="http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                        alt=""
                        style={{ width: "30px", height: "30px" }}
                      />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <RemoveCircleIcon />
                        <img alt="" src={require("../assets/threedots.png")} />
                      </div>
                    </div>
                  )}
                  {includeCommute && (
                    <div
                      style={{
                        width: "250px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "fit-content",
                        backgroundColor: "lightgray",
                        padding: "1% 2%",
                        borderRadius: "11px",
                        fontFamily: "Roboto",
                      }}
                    >
                      Your Location
                    </div>
                  )}
                </div>

                {renderWaypoints()}
              </div>
              <div style={{ padding: "3%", fontSize: "15px", fontWeight: 500 }}>
                <TravelSummaryTable
                  distance={distance}
                  duration={duration}
                  eta={eta}
                  filteredOutlets={filteredOutlets}
                  totalPotentialSale={totalPotentialSale}
                />
              </div>
              {selectedOutlet && (
                <div style={{ marginTop: "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      borderRadius: "4px",
                    }}
                    onClick={() => toggleSection("table")}
                  >
                    <h4 style={{ margin: 0 }}>{selectedOutlet.Name}</h4>

                    {openSection === "table" ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </div>
                  {openSection === "table" && (
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>Category Size</TableCell>
                            <TableCell>{selectedOutlet.Category}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Last Bill Date</TableCell>
                            <TableCell>
                              {new Date(
                                selectedOutlet.Last_Order_Date
                              ).toLocaleDateString()}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Bill Cuts (12 months)</TableCell>
                            <TableCell>{selectedOutlet.Total_Orders}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Bill Value (12 months)</TableCell>
                            <TableCell>
                              ₹
                              {parseFloat(
                                selectedOutlet.Total_Sales
                              ).toLocaleString("en-IN")}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>No of Days of No Billing</TableCell>
                            <TableCell>
                              {Math.floor(
                                (new Date() -
                                  new Date(selectedOutlet.Last_Order_Date)) /
                                  (1000 * 60 * 60 * 24)
                              )}{" "}
                              days
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>
              )}
              {selectedLeg && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      borderRadius: "4px",
                    }}
                    onClick={() => toggleSection("steps")}
                  >
                    <h4>Steps to Reach {selectedOutlet?.Name}</h4>
                    {openSection === "steps" ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </div>
                  {openSection === "steps" && (
                    <div>
                      <div>
                        <strong>Start Address:</strong>{" "}
                        {selectedLeg.start_address}
                      </div>
                      <div>
                        <strong>Destination:</strong> {selectedLeg.end_address}
                      </div>
                      <ul>
                        {selectedLeg.steps.map((step, idx) => (
                          <li
                            key={idx}
                            dangerouslySetInnerHTML={{
                              __html: step.instructions,
                            }}
                          ></li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
              {googleMapsLink && (
                <a
                  href={googleMapsLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "underline",
                    color: "rgb(11 134 153)",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                  }}
                >
                  Open in Google Maps
                </a>
              )}
            </div>
          ) : (
            <>
              <Box
                sx={{
                  borderRadius: "3px",
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "1%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2%",
                      width: "80%",
                    }}
                  >
                    <Typography
                      style={{ fontWeight: "bolder", fontFamily: "Roboto" }}
                    >
                      Current Location
                    </Typography>
                    <div
                      style={{
                        cursor: "pointer",
                        padding: "3px 5px",
                        backgroundColor: "#f0f0f0",
                        fontSize: "12px",
                        borderRadius: "5px",
                        fontFamily: "Roboto",
                      }}
                      onClick={toggleModal}
                    >
                      Change Location
                    </div>
                  </div>
                  {isModalOpen && (
                    <div
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "300px",
                        background: "white",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        zIndex: 1000,
                        padding: "15px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: 0,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ fontSize: "16px", fontFamily: "Roboto" }}>
                          Change Location
                        </div>
                        <button
                          onClick={() => {
                            setUseCurrentLocation(true);
                            setFilteredOutlets([]);
                            toggleModal();
                          }}
                          style={{
                            padding: "8px 5px",
                            color: "black",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            fontFamily: "Roboto",
                          }}
                        >
                          Use Current Location
                        </button>
                      </div>
                      <div
                        style={{
                          padding: "4%",
                          display: "flex",
                          flexDirection: "column",
                          fontFamily: "Roboto",
                        }}
                      >
                        Latitude:
                        <input
                          type="number"
                          value={latitude}
                          onChange={(e) => {
                            setLatitude(e.target.value);
                            setError("");
                          }}
                        />
                      </div>
                      <div
                        style={{
                          padding: "4%",
                          display: "flex",
                          flexDirection: "column",
                          fontFamily: "Roboto",
                        }}
                      >
                        Longitude:
                        <input
                          type="number"
                          value={longitude}
                          onChange={(e) => {
                            setLongitude(e.target.value);
                            setError("");
                          }}
                        />
                      </div>
                      {error && (
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "8px",
                            textAlign: "center",
                          }}
                        >
                          {error}
                        </div>
                      )}
                      <div
                        style={{
                          marginTop: "12px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          onClick={() => {
                            setUseCurrentLocation(false);
                            handleUpdateLocation();
                            setFilteredOutlets([]);
                            toggleModal();
                          }}
                          style={{
                            padding: "8px 16px",
                            color: "white",
                            backgroundColor: "#007bff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            fontFamily: "Roboto",
                          }}
                        >
                          Update
                        </button>
                        <button
                          onClick={() => {
                            toggleModal();
                          }}
                          style={{
                            padding: "8px 16px",
                            color: "black",
                            backgroundColor: "#f5f5f5",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            cursor: "pointer",
                            fontFamily: "Roboto",
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                  {(filteredOutlets?.length > 0 ||
                    filteredOutlets?.length === 0) &&
                    hasSearched && (
                      <button
                        onClick={() => setIsExpanded(!IsExpanded)}
                        style={{
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                          height: "24px",
                        }}
                      >
                        {IsExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                      </button>
                    )}
                </div>

                <Box style={{ fontFamily: "Roboto" }}>
                  {currentAddress || "Loading address..."}
                </Box>
              </Box>

              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress size="2rem" sx={{ color: "red" }} />
                </div>
              ) : filteredOutlets?.length === 0 && hasSearched ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    color: "red",
                    fontSize: "20px",
                    fontWeight: "bolder",
                    fontFamily: "Roboto",
                  }}
                >
                  No outlets found
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      </Box>
    </Container>
  );
};
export default SlidingComponent1;
