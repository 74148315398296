import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Container,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AssistantIcon from "@mui/icons-material/Assistant";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import UnibicLogo from "../../assets/unibic_logo.png";
import Noviro from "../../assets/Noviro.png";

function UnibicNav({ isAuthenticated, handleLogout }) {
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = JSON.parse(sessionStorage.getItem("userDetails") || "{}");
  const role = userDetails?.role;

  const getSelectedValue = () => {
    switch (location.pathname) {
      case "/home":
        return 0;
      case isAuthenticated ? "/Logout" : "/login":
        return 1;
      default:
        return -1;
    }
  };
  const [value, setValue] = useState(getSelectedValue());
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const storedUser = sessionStorage.getItem("userDetails");
  const user = JSON.parse(storedUser);

  useEffect(() => {
    setValue(getSelectedValue());
  }, [location]);

  const handleLogoutConfirm = () => {
    if (!user) {
      setOpenLogoutDialog(false);
      navigate("/login");
      return;
    }
    setOpenLogoutDialog(false);
    handleLogout(user?.username);
    navigate("/login");
  };

  const handleLogoutClick = () => {
    setOpenLogoutDialog(true);
  };

  const handleDialogClose = () => {
    setOpenLogoutDialog(false);
    navigate("/home");
  };

  return (
    <>
      {isMediumDown ? (
        <BottomNavigation
          showLabels
          value={value}
          onClick={(event, newValue) => {
            setValue(newValue);

            isAuthenticated ? handleLogoutClick() : navigate("/login");
          }}
          sx={{
            display: window.location.pathname === "/login" ? "none" : "",
            position: isMediumDown ? "sticky" : "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            background: "#f0f0f0",
            zIndex: theme.zIndex.drawer + 1,
            "& .Mui-selected": {
              color: "#d52c2c",
            },
            "& .MuiBottomNavigationAction-root.Mui-selected": {
              "& .MuiBottomNavigationAction-label": {
                color: "#d52c2c",
              },
              "& .MuiSvgIcon-root": {
                color: "#d52c2c",
              },
            },
          }}
        >
          <BottomNavigationAction
            label={isAuthenticated ? "Logout" : "Login"}
            icon={
              isAuthenticated ? (
                <div
                  style={{
                    width: 25,
                    height: 25,
                    borderRadius: "50%",
                    backgroundColor: "#d52c2c",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  {user?.name[0]?.toUpperCase()}
                </div>
              ) : (
                <AccountCircleIcon />
              )
            }
          />
        </BottomNavigation>
      ) : (
        <AppBar
          position="fixed"
          sx={{
            boxShadow: "none",
            zIndex: theme.zIndex.drawer + 1,
            background: "white",
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              height: "100%",
              maxWidth: "100%",
              padding: "0 2%",
            }}
          >
            <div>
              <img
                fetchpriority="high"
                width={"110"}
                height={"50"}
                src={UnibicLogo}
                alt="Unibic Logo"
                sizes="(max-width: 500px) 100vw, 500px"
              />
            </div>
            <div style={{ marginLeft: "auto" }}>
              <img
                fetchpriority="high"
                width={"110"}
                height={"40"}
                src={Noviro}
                alt="Noviro Logo"
                sizes="(max-width: 500px) 100vw, 500px"
              />
            </div>
            <Button
              onClick={() => {
                isAuthenticated ? handleLogoutClick() : navigate("/login");
              }}
              sx={{
                color: "hsl(207, 12%, 43%)",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              {isAuthenticated && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: "60px",
                  }}
                >
                  <div
                    style={{
                      width: 32,
                      height: 32,
                      borderRadius: "50%",
                      backgroundColor: "#D52C2C",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    {user?.name[0]?.toUpperCase()}
                  </div>
                  Logout
                </div>
              )}
            </Button>
          </Toolbar>
        </AppBar>
      )}
      <Dialog
        open={openLogoutDialog}
        onClose={handleDialogClose}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle id="logout-dialog-title">Logout Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ backgroundColor: "", color: "red" }}
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ backgroundColor: "", color: "red" }}
            onClick={handleLogoutConfirm}
          >
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UnibicNav;
